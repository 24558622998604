@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");

@layer utilities {
  .visible {
    visibility: visible;
  }
  .invisible {
    visibility: hidden;
  }
}
:root {
  --Primary-color: #1158a6;
  --Secondary-color: #d1d5db;
  --color-text: #000;
}

.text-tabs-standar {
  color: #000;
  font-family: Arial;
  font-weight: bold;
  font-size: 0.8rem;
}

.bgtabs {
  background: var(--Primary-color);
}

.border-color-tabs-standar {
  border: solid 1px var(--Primary-color);
  border-radius: 0.2rem;
  padding: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.bg-color-btn {
  background: var(--Primary-color);
  font-family: Arial;
  font-size: small;
}

.titelAccordionHeader {
  color: var(--color-text);
  font-family: "Arial Black";
}

.label-date{
  font-family: Arial;
    font-weight: 700;
    font-size: 1rem;
}

.btnViewPreview {
  width: auto;
  border: solid 1px var(--Primary-color);
  border-radius: 5px;
  color: var(--color-text);
  box-shadow: 0px 4px 16px rgb(0 0 0 / 20%);
  min-width: 220px;
}

.bg-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.bg-disable{
  background: rgba(0, 0, 0, 0.07);
}


.placeholder-inputs {
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: 400;
  font-family: Arial;
  font-size: 0.7rem;
  height: 34.13px;
  line-height: 11px;
}

.primary-title {
  font-family: Arial black;
  color: var(--Primary-color);
  font-size: 1.4rem;
}

.tab-title {
  font-family: Arial black;
  color: var(--color-text);
  font-size: 1rem;
}

[type="checkbox"],
[type="radio"] {
  color: #aaa7b2 !important;
}

.secondary-title {
  font-family: Arial;
  color: var(--Primary-color);
  font-size: 0.8rem;
  font-style: normal;
}
.text-btn-Previex {
  color: var(--color-text);
  font-family: 0.8rem;
  font-family: Arial;
  font-weight: bold;
}


.contain-tab{
  display: flex;
    /* overflow-x: scroll;
    overscroll-behavior-x: contain;
    scroll-snap-type: x proximity; */
    justify-content: center;
    flex-wrap: wrap;
   
}

.option-GRI:hover{
  /* color: var(--Primary-color); */
}

.text-btn {
  font-family: Arial;
  color: #ffff;
  font-size: 0.8rem;
  font-style: normal;
}

.content-mobile-menu{
  background: var(--Primary-color);
  color: white;
  border-radius: 15px;
}

.btn-mobile-menu:hover{
  background: white;

}

.item-navbar:hover{
  color: var(--Primary-color);
}

.icon-block {
  color: var(--Primary-color);
}
.block:hover{
color: var(--Primary-color);
}

.from-forget {
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: 700;
  font-family: Arial regular;
  font-size: 0.7rem;
}

.from-register {
  color: var(--Primary-color);
  font-style: normal;
  font-weight: 700;
  font-family: Arial regular;
  font-size: 0.7rem;
}

.from-question {
  font-style: normal;
  font-weight: 700;
  font-family: Arial regular;
  font-size: 0.7rem;
  color: var(--Primary-color);
}

.text-error{
  color: var(--Primary-color);
  font-family: arial regular;
}

.container-form {
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 20%);
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 3rem;
  margin-top: 3rem;
}

.type-imagesGRI{
  position: relative;
  scroll-snap-align: center;
  max-width: 300px;  
}

 .GRI-image{
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
 }  
 .NImagen-GRI{
  grid-row: 7;
    grid-column: 1/3;
 }

 .NImagen{
  margin-top: -38px;
  margin-left: 44px;
  width: 13px;
  height: 13px;
 }

.form-elements {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
  grid-template-rows: repeat(6, 1fr);
  column-gap: 1rem;
}

.form-editCompany {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
  grid-template-rows: repeat(4, 1fr);
  column-gap: 1rem;
}

.PhoneInput {
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 8px;
  height: 34.13px;
  padding: 8px 0px 8px 10px;
}

.PhoneInputInput {
  width: 100%;
  outline: none;
  background: none;
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: 400;
  font-family: Arial;
  font-size: 0.7rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 34.13px;
}

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.35em;
  width: 40px;
}

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountryIcon .PhoneInputCountryIcon--border {
  box-shadow: 0px 4px 16px rgb(0 0 0 / 20%);
  background: var(--Secondary-color);
}

.PhoneInputCountryIcon {
  width: 1em;
  height: 1em;
}

.custom-pickers{
  position: relative;
  max-width: 242px;
  min-width: 220px;
}

.icon-calendar-start-date{
  right: 0.5rem;
  cursor: pointer;
  top: 0.7rem;
}

.btn-generarReporte{
  background: var(--Primary-color);
    width: 137px;
    height: 40px;
    color: #ffff;
    font-family: Arial;
}

.btn-cancel-reporte{
    width: 137px;
    height: 40px;
    font-family: Arial;
    font-weight: 600;

}

.completed{

  font-family: Arial;
  font-weight: 700;
}

.error{
  color: red;
  font-family: Arial;
  font-weight: 700;
}

.border-select{
  border: 1px solid var(--Primary-color);
}
.imagesGRI{
  object-fit: cover; 
  border-radius: 15px;
  cursor: pointer;
  
}
.item-imagesGRI{
  position: relative;
  scroll-snap-align: center;
  width: 70%;
  min-width: 190px;
  max-width: 300px;
  height: 130px;
  
}

.container-imagesGRI{
  display: flex;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
  gap: 0.8rem;
  scrollbar-width: none
  
}


.item-imagesGRI::-webkit-scrollbar {
  width: 0;
}

.container-imagesGRI::-webkit-scrollbar {
  -webkit-appearance: none;
}

.container-imagesGRI::-webkit-scrollbar:vertical {
  width:10px;
}
.container-imagesGRI::-webkit-scrollbar-button:increment,.contenedor::-webkit-scrollbar-button {
  display: none;
} 

.container-imagesGRI::-webkit-scrollbar:horizontal {
  height: 10px;
}

.container-imagesGRI::-webkit-scrollbar-thumb {
  background-color: var(--Secondary-color);
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.container-imagesGRI::-webkit-scrollbar-track {
  border-radius: 10px;  
}
.labelcalendar{
  font-family: Arial;
  font-weight: 700;
  font-size: 1rem;
}

.icon-calendar-end-date{
  right: 0.6rem;
    top: 0.7rem;
    cursor: pointer;
}
.PhoneInputCountrySelectArrow {
  display: block;
  content: "";
  width: 0.3rem;
  height: 0.3rem;
  margin-left: 0.35px;
  border-style: solid;
}

.PhoneInputCountryIconImg {
  width: 100%;
  height: 100%;
}

.input:not(.react-phone-number-input__input) {
  padding-left: 0.4em;
  padding-right: 0.4em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border-radius: 0.2em;
  border: 1px solid #cfcfcf;
  background-color: #fcfcfc;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.btn-register {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-row: 8;
  grid-column: 1/3;
}

.reset-password {
 max-width: 180px;
}

.btn-register button {
  background: var(--Primary-color);
  width: 137px;
  height: 40px;
}

body {
  margin: 0;
}

.message {
  width: 214px;
}

.text-title {
  font-family: "Arial Black";
}

.text-parrafo {
  font-family: "Arial regular";
}

@layer components {
  .card {
    background-color: theme("colors.white");
    border-radius: theme("borderRadius.lg");
    padding: theme("spacing.6");
    box-shadow: theme("boxShadow.xl");
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.logoGRI {
  width: 45px;
}

.underline {
  text-decoration-color: var(--Primary-color);
  text-decoration-thickness: 2px;
}

.underline:hover {
  text-decoration-color: var(--Primary-color);
  text-decoration: underline;
}

.footer .icon-link {
  display: flex;
  justify-content: center;
}

.footer {
  background-color: var(--Secondary-color);
}

.accordionHeader {
  background-color: #f1f2f2;
}

.linkendin img,
.twitter img,
.Medium img,
.youtobe img {
  width: 35x;
  height: 35px;
}

.background-image {
  background-image: url("../assets/images/background.jpeg");
  height: 550px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  max-width: 1440px;
  margin: 0 auto;
}

.content-header{
  max-width: 1440px;
  margin: 0 auto;
}

.options {
  display: grid;
}

.options-pages {
  display: grid;
  max-width: 1273px;
  margin: 0 auto;
}

.container-forgotpassword {
  height: 375px;
}

.forgotpassword {
  height: 260px;
}

.content-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  align-content: center;
  padding: 79px;
  justify-content: center;
  justify-items: end;
  gap: 3.5rem;
}

.description {
  display: flex;
  flex-wrap: wrap;
  gap: 1.3rem;
  padding: 10px;
}

.materials,
.templates-GRI {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 180px;
  align-items: center;
  text-align: start;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 25px 5px;
  background: #f5f6f7;
  max-width: 400px;
  cursor: pointer;
}

.materials {
  justify-self: end;
  border: 1px solid var(--Primary-color);
  
}

.templates-GRI {
  justify-self: start;
  border: 1px solid var(--Primary-color);
 
}

.materials img,
.templates-GRI img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background: #d1d5db;
  align-self: flex-start;
  margin: 15px;
  justify-self: center;
  border: 0;
}

.background-image div {
  display: grid;
  grid-column: 1/3;
}

.background-image div p {
  align-self: center;
  max-width: 400px;
  text-align: start;
  padding: 27px;
  line-height: 1.8rem;
  margin-top: 13rem;
}

.boxshadow {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.container {
  max-width: 831px;
  margin: auto;
}

.accordion {
  width: 100%;
  max-width: 850px;
  color: #000;
}

.accordion_icon {
  margin-left: auto;
  transition: transform 0.65s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion__content {
  background: var(#ffff);
  overflow: hidden;
  transition: max-height 0.6s ease;
  max-height: auto!important;
}

/* .accordion1{
  pointer-events:none
} */

.accordion-head {
  background: #f1f2f2;
}

.modal-update-NoGRI{
width: 290px;
background-color: #fffff;
}

[type="text"] {
  border-radius: 5px !important;
}

.react-datepicker__input-container [type="text"] {
  border: solid 1px var(--Primary-color);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}

.accordion-head:hover .active {
  background: var(--Primary-color);
}

.accordion-head .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.showaccordion-content {
  border: solid 1px #f1f2f2;
}
.accordion-head p {
  font-weight: 600;
}

.accordion-content {
  background: var(#ffff);
  overflow: hidden;
  transition: all 600ms ease-in-out;
  height: 0;
  opacity: 0;
}

.accordion-content.show {
  opacity: 1;
  height: 100px;
}

/**tabs**/
.container-accordion {
  position: relative;
  max-width: 1024px;

  margin: 0 auto;
  
}

.bloc-tabs {
  display: flex;
}
.tabs {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  border: 1px solid var(--Primary-color);
  box-sizing: content-box;
  position: relative;
  outline: none;
  border-radius: 5px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  font-family: Arial;
  font-weight: 700;
  font-size: 1rem;
  position: relative;
    /* width: 70%;
    min-width: 190px;
    max-width: 280px; */
}
.container-template {
  max-width: 1024px;
}

.active-tabs {
  background: var(--Primary-color);
  color: #ffff;
}

.tabs:hover{
  background-color: var(--Primary-color);
  color: white;
}

.container-accordion:hover{
  background-color: var(--Primary-color);
}

.btnViewPreview:hover{
  background-color: var(--Primary-color);
  color: white;
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 2.75rem;
  width: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}

.sectorial{
  display: flex;
  /* justify-content: center;
  max-width: 43%; */
  font-family: Arial;
  font-weight: 700;
  margin-left: 20px;
  color: var(--color-text);
}

.GRI11{
  display: none;
}

.sector{
  display: none;
}

.btn-accordion{
max-width: 935px;
}

.headlessui-dialog-panel{
  margin-inline: auto;
}

.btn-generarReporte {
  font-family: 'Anek Malayalam', sans-serif;
  background: var(--Primary-color);
  border: none;
  outline: none;
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  min-width: 137px;
  min-height: 36px;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 16px rgb(0 0 0 / 20%);
}

.btn-generarReporte:disabled {
  background: #1158a694!important;
  color: white!important;
  cursor: not-allowed!important;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 450px) {
  .form-elements {
    min-width: 212px;
    margin: 0 auto;
    padding: 15px;
  }

  .NImagen-GRI{
    grid-row: 12;
    grid-column: 1/2;
  }

  .btn-register {
    min-width: 192px;
    grid-row: 13;
    grid-column: 1/1;
  }
  .content-options{
    justify-self: center;
    padding: 20px 0px 20px 0px;
  }

  .description{
    padding: 0;
  }

  .container-form {
    max-width: 299px;
  }

 
}

@media (min-width: 930px){
  .contain-tab{
    justify-content: center;

    /* overflow-x:hidden; */
  }


}

@media(max-width: 769px){

  .content-options{
    justify-self: center;
  }

  .container-date{
    justify-content: center;
  }
 
}
